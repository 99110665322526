<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        <!-- <Button
          label="اضافه جديد"
          icon="pi pi-plus"
          class="p-ml-2 p-button-success"
          @click="$router.push('league/add')"
        /> -->
      </template>

      <template #right>
        <!-- <Button icon="pi pi-search" class="p-ml-2" />-->
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/admin/academies')"
        />
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      class="p-datatable-customers"
      :rows="100"
      dataKey="id"
      :rowHover="true"
      :selection.sync="selectedItems"
      :loading="loading"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100]"
      currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
    >
      <template #header>
        <div class="table-header">
          قائمه المسابقات المشترك بها - {{ bodyA.userName }}

          <div style="text-align:left">
            <MultiSelect
              :value="selectedColumns"
              :options="columns"
              optionLabel="header"
              @input="onToggle"
              placeholder="اختيار الاعمده"
              style="width: 20em"
            />
          </div>
        </div>
      </template>
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>
      <!-- <Column selectionMode="multiple" field="id" headerStyle="width: 3em" /> -->

      <Column
        field="image"
        header="الشعار"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <img :src="$baseUploadURL + slotProps.data.image" class="dt-image" />
        </template>
      </Column>

      <Column
        v-for="(col, index) of selectedColumns"
        :key="col.field + '_' + index"
        :field="col.field"
        :header="col.header"
        :sortable="true"
      />

      <Column field="id" bodyStyle="text-align: center; overflow: visible">
        <template #body="slotProps">
          <Button
            type="button"
            @click="$router.push(`years/${slotProps.data.id}/${academieId}`)"
            icon="pi pi-pencil"
            class="p-button-success p-ml-2  p-button-rounded"
            v-tooltip="'تعديل'"
          ></Button>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      selectedItems: [],
      selectedColumns: [],
      columns: [
        {
          field: 'name',
          header: 'الاسم',
        },
        {
          field: 'location',
          header: 'المكان',
        },
      ],
      loading: true,
      academieId: null,
      bodyA: {
        userName: null,
      },
    };
  },
  methods: {
    getData() {
      this.$http
        .get(`years/findAllYearsAcademie?academieId=${this.academieId}`)
        .then(
          (response) => {
            this.loading = false;
            const list = response.data;
            const listMap = list.map((e) => e.yearId.leaguesId);
            const ids = listMap.map((o) => o.id);
            const filtered = listMap.filter(
              ({ id }, index) => !ids.includes(id, index + 1),
            );

            this.list = filtered.length > 0 ? filtered : [];
          },
          (err) => {
            this.loading = false;
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
    },
    onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col));
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
  },
  created() {
    if (this.$route.params.id) {
      this.academieId = this.$route.params.id;

      this.$http.get(`academies/${this.academieId}`).then(
        (res) => {
          this.bodyA = res.data;
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );

      this.getData();
      this.selectedColumns = this.columns;
    }
  },
};
</script>

<style></style>
